import logo from './flohmarkt.webp';
import veranstaltungen from './Veranstaltungen24.png';
import werbung from './Werbung.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
       <p>
        Flohmarkt am 09.06.2024 fällt leider aus!
       </p>
       <p>
       <img src={logo} className="App-logo" alt="logo" />
       </p>
      </header>
      
      <body className="App-body">
        <p>
        <img src={veranstaltungen} className="App-veranstaltungen" alt="veranstaltungen" />
        <img src={werbung} className="App-werbung" alt="werbung" />
        </p>
      </body>

      <footer className="App-footer">
        impressum: verantwortlich für die Inhalte; Dominik Fritsch, Böhmerwaldstraße 51, 93105 Tegernheim, 0176/45640927
      </footer>
    </div>
  );
}

export default App;
